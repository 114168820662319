import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { endpoint } from "../../config";

const axiosConfig = {
  headers: { "Content-Type": "text/plain" },
};

const ApiContext = createContext({});

export const ApiProvider = ({ children }) => {
  const refreshTime = 30 * 60 * 1000;
  const [authorized, setAuthorized] = useState(false);
  const setAuthorizationToken = (token) => {
    console.log(token);
    if (token) {
      setAuthorized(true);
      axios.defaults.headers.common["x-auth-token"] = `Bearer ${token}`;
    } else {
      setAuthorized(false);
      delete axios.defaults.headers.common["x-auth-token"];
    }
  };
  const routes = {
    auth: {
      login: async (credentials) => {
        return await axios.post(
          `${endpoint}/webUser/logon`,
          credentials,
          axiosConfig
        );
      },
    },
    activity: {
      get: async (numberOfRecords) => {
        return await axios.get(
          `${endpoint}/activity/?count=${numberOfRecords}`
        );
      },
    },
    box: {
      get: async (id) => {
        console.log(axios.defaults);
        return (await id)
          ? axios.get(`${endpoint}/rekyBox/${id}`)
          : axios.get(`${endpoint}/rekyBox/`);
      },
      post: async (box) => {
        return await axios.post(`${endpoint}/rekyBox/`, box);
      },
      delete: async (box) => {
        return await axios.delete(`${endpoint}/rekyBox/${box.id}`);
      },
    },
    client: {
      get: async (id) => {
        return (await id)
          ? axios.get(`${endpoint}/client/${id}`)
          : axios.get(`${endpoint}/client/`);
      },
      post: async (client) => {
        return await axios.post(`${endpoint}/client/`, client);
      },
      deactivate: async (client) => {
        return await axios.post(`${endpoint}/client/${client.id}`, client);
      },
      delete: async (client) => {
        return await axios.delete(`${endpoint}/client/${client.id}`);
      },
    },
    pass: {
      get: async (id) => {
        return (await id)
          ? axios.get(`${endpoint}/accessPass/${id}`)
          : axios.get(`${endpoint}/accessPass/`);
      },
      getByBox: async (box) => {
        return await axios.get(
          `${endpoint}/accessPass/findByBoxNumber/${box.id}`
        );
      },
      post: async (pass) => {
        return await axios.post(`${endpoint}/accessPass/`, pass);
      },
      delete: async (pass) => {
        return await axios.delete(`${endpoint}/accessPass/${pass.id}`);
      },
    },
    user: {
      get: async (id) => {
        return (await id)
          ? axios.get(`${endpoint}/webUser/${id}`)
          : axios.get(`${endpoint}/webUser/`);
      },
      post: async (user) => {
        return await axios.post(`${endpoint}/webUser/`, user);
      },
      deactivate: async (user) => {
        return await axios.post(`${endpoint}/webUser/${user.id}`, user);
      },
      delete: async (user) => {
        return await axios.delete(`${endpoint}/webUser/${user.id}`);
      },
    },
  };

  return (
    <ApiContext.Provider
      value={{ ...routes, setAuthorizationToken, authorized, refreshTime }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const ApiConsumer = ApiContext.Consumer;

export const useApi = () => useContext(ApiContext);
