import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import jwt_decode from "jwt-decode";
import { useApi } from "../api";
import { differenceInSeconds } from "date-fns";
import { useAsync } from "../async";
import { SubmissionError } from "redux-form";
const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const [showWarning, setShowWarning] = useState();
  const { startAction, finishAction } = useAsync();
  const [roleId, setRoleId] = useState(0);
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const api = useApi();
  const [expiry, setExpiry] = useState();

  const handleDashboardRedirect = useCallback(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/register"
    ) {
      window.location.replace("/dashboard");
    }
  }, []);

  const handleLoginRedirect = useCallback(() => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/register"
    ) {
      window.location.replace("/");
    }
  }, []);
  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    const expires = localStorage.getItem("expiry");
    //const expires = add(new Date(), { seconds: 50 }).getTime();
    const roleID = localStorage.getItem("role");
    if (differenceInSeconds(new Date(parseInt(expires)), new Date()) > 60) {
      setRoleId(parseInt(roleID));
      setToken(jwtToken);
      setExpiry(parseInt(expires));
    } else {
      handleLoginRedirect();
    }
    if (!jwtToken) {
      handleLoginRedirect();
    }
  }, [handleLoginRedirect]);

  useEffect(() => {
    if (token) {
      console.log(token);
      api.setAuthorizationToken(token);
      handleDashboardRedirect();
    }
  }, [token, api, handleDashboardRedirect, expiry]);

  const checkExpiry = useCallback(() => {
    console.log("tick");
    if (token && expiry) {
      if (differenceInSeconds(new Date(expiry), new Date()) < 60) {
        console.log("Token expires within the next minute");
        setShowWarning(true);
      }
    } else {
      console.log("No Token");
      setShowWarning(false);
    }
  }, [expiry, token]);

  useEffect(() => {
    checkExpiry();
    const interval = setInterval(checkExpiry, 60000);
    return () => clearInterval(interval);
  }, [checkExpiry]);

  useEffect(() => {
    const loadUser = async () => {
      if ((user === undefined || user === null) && token) {
        const userId = localStorage.getItem("id");
        if (userId) {
          await api.user.get(userId).then((res) => {
            setUser(res.data.tblwebuser);
          });
        }
      }
    };
    loadUser();
  }, [api, user, token]);

  const role = useMemo(() => {
    return {
      isSuperAdmin: roleId === 1,
      isClientAdmin: roleId === 2,
      isClientUser: roleId === 3,
    };
  }, [roleId]);

  const login = async (credentials) => {
    startAction();
    return await api.auth
      .login(credentials)
      .then((res) => {
        const token = res.data.data;
        const webuser = res.data.webuser;
        const roleID = webuser.tblACLID;

        const decoded = jwt_decode(token);
        console.log(decoded);
        const expires = decoded.exp * 1000;

        console.log(expires);

        localStorage.setItem("expiry", expires);
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("role", roleID);
        localStorage.setItem("id", webuser.id);

        setExpiry(expires);
        setUser(webuser);
        setRoleId(roleID);
        setToken(token);
        finishAction();
      })
      .catch((err) => {
        finishAction();
        console.log(err);
        throw new SubmissionError({
          _error: "Invalid login credentials. Please try again.",
        });
      });
  };
  const logout = async () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("expiry");
    setToken(null);
    setRoleId(0);
    setUser(undefined);
    setExpiry(null);
    handleLoginRedirect();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        login,
        logout,
        token,
        showWarning,
        expiry,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const AuthConsumer = AuthContext.Consumer;
export const useAuth = () => useContext(AuthContext);
